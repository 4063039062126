import React from 'react';
import './Values.css';

// Importação de ícones - você pode ajustar de acordo com a biblioteca de ícones que usa
import { FaBullseye, FaHandshake, FaUsers } from 'react-icons/fa';

const Values = () => {
    return (
        <section id="valores" className="values-section">
            <span className="values-label">SOBRE NÓS</span>
            <h2>Missão, Valores e Cultura</h2>

            <div className="values-container">
                <div className="value-item">
                    <FaBullseye className="value-icon" />
                    <h3>Missão</h3>
                    <p>Criamos aplicativos personalizados para atender suas necessidades de negócio, garantindo performance e design de alta qualidade.</p>
                </div>

                <div className="value-item">
                    <FaHandshake className="value-icon" />
                    <h3>Valores</h3>
                    <p>De landing pages a e-commerces robustos, criamos sites que não só impressionam, mas convertem.</p>
                </div>

                <div className="value-item">
                    <FaUsers className="value-icon" />
                    <h3>Cultura</h3>
                    <p>Projetos centrados no usuário, oferecendo uma experiência visual única e eficiente.</p>
                </div>
            </div>
        </section>
    );
};

export default Values;
