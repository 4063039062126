import React from 'react';
import './Testimonials.css';

const Testimonials = () => {
    return (
        <section id="depoimentos" className="testimonials-section" style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/logo-white.png'})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'left top',
        }}>
            <span className="depoimentos-label">O QUE DIZEM SOBRE NÓS</span>

            <div className="testimonial-content">
                <div className="stars">
                    {[...Array(5)].map((_, index) => (
                        <span key={index} className="star">★</span>
                    ))}
                </div>
                <p className="testimonial-text">“Agradeço imensamente à equipe pelo trabalho impecável e pela atenção dedicada, é perceptível o comprometimento de todos em manter uma comunicação respeitosa e uma entrega de alta qualidade, o que faz toda a diferença para nós como cliente.”</p>
                <div className="testimonial-footer">
                    <img src="client.png" alt="Lorena Silva" className="testimonial-photo" />
                    <div className="author-info">
                        <p className="author">Lorena Silva</p>
                        <p className="role">Casa das Pedras</p>
                    </div>
                </div>
                <a href="#contato" className="cta-btn">Seja também nosso Cliente!</a>
            </div>
        </section>
    );
};

export default Testimonials;
