import React from 'react';
import './Services.css';

import { FaLaptop, FaLayerGroup, FaPencilRuler, FaBullhorn, FaCode, FaMobileAlt } from 'react-icons/fa';

const Services = () => {
    return (
        <section 
            id="servicos" 
            className="services-section" 
            style={{
                backgroundImage: `url(${process.env.PUBLIC_URL + '/logo-white.png'})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right top',
            }}
        >
            <h2>O que fazemos</h2>
            <p>Confira os nossos serviços</p>
            <div className="services-container">
                <div className="service-item">
                    <FaLaptop className="service-icon" />
                    <h3>Websites</h3>
                    <p>Desenvolvemos sites personalizados que combinam design moderno e funcionalidade, proporcionando uma experiência única para seus visitantes e fortalecendo sua presença online.</p>
                </div>
                <div className="service-item">
                    <FaLayerGroup className="service-icon" />
                    <h3>Landing Pages</h3>
                    <p>Criamos landing pages otimizadas para conversão, com foco em atrair e engajar seus clientes, impulsionando resultados e aumentando o impacto da sua marca.</p>
                </div>
                <div className="service-item">
                    <FaPencilRuler className="service-icon" />
                    <h3>UI/UX Design</h3>
                    <p>Transformamos ideias em experiências visuais impactantes, priorizando a usabilidade e a satisfação do usuário. Nossa equipe projeta interfaces intuitivas e atraentes.</p>
                </div>
                <div className="service-item">
                    <FaBullhorn className="service-icon" />
                    <h3>Branding</h3>
                    <p>Ajudamos sua marca a contar uma história envolvente e autêntica, criando identidade visual e posicionamento estratégico para se destacar no mercado.</p>
                </div>
                <div className="service-item">
                    <FaCode className="service-icon" />
                    <h3>Software Sob Medida</h3>
                    <p>Desenvolvemos soluções sob medida para atender às necessidades exclusivas do seu negócio, integrando tecnologias que otimizam processos e geram resultados.</p>
                </div>
                <div className="service-item">
                    <FaMobileAlt className="service-icon" />
                    <h3>Aplicativos Mobile</h3>
                    <p>Criamos aplicativos mobile com design inovador e funcionalidades práticas, proporcionando aos usuários uma experiência completa e acessível.</p>
                </div>
            </div>
        </section>
    );
};

export default Services;
