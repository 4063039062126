import React, { useState } from 'react';
import './Header.css';

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header>
            <div className="container">
                <div className="logo">
                    <img src="logo.png" alt="Galuma Logo" />
                </div>

                <nav className={`menu ${isMobileMenuOpen ? 'active' : ''}`}>
                    <ul>
                        <li><a href="#servicos">O que fazemos</a></li>
                        <li><a href="#projetos">Projetos</a></li>
                        <li><a href="#depoimentos">Depoimentos</a></li>
                        <li><a href="#faq">Perguntas Frequentes</a></li>
                    </ul>
                </nav>

                <div className="cta-button">
                    <a href="#contato">Entrar em contato</a>
                </div>

                <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    );
};

export default Header;
