import React, { useEffect } from 'react';
import './HeroSection.css';

const HeroSection = () => {
    useEffect(() => {
        const heroSection = document.querySelector('.hero-section');

        const loadBackground = () => {
            heroSection.style.backgroundImage = `linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, white 100%), url(${process.env.PUBLIC_URL + '/background-white.png'})`;
        };

        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        loadBackground();
                        observer.unobserve(entry.target);
                    }
                });
            });
            observer.observe(heroSection);
        } else {
            loadBackground();
        }
    }, []);

    return (
        <section className="hero-section">
            <div className="circles">
                <img src={`${process.env.PUBLIC_URL}/partners/gabriel.jpeg`} alt="Gabriel" className="circle" width="60" height="60" />
                <img src={`${process.env.PUBLIC_URL}/partners/luis.jpeg`} alt="Luis" className="circle" width="60" height="60" />
                <img src={`${process.env.PUBLIC_URL}/partners/marcelo.jpeg`} alt="Marcelo" className="circle" width="60" height="60" />
            </div>
            <h1>Transformamos suas ideias em <span className="highlight">soluções digitais!</span></h1>
            <p>Soluções digitais sob medida, prontas para levar sua empresa ao topo</p>
            <a href="#contato" className="cta-btn">Solicite um orçamento</a>
            <div className="technology-marquee">
                <p>Ferramentas e tecnologias</p>
                <div className="marquee-content">
                    {['image 4.png', 'image 3.png', 'image 5.png', 'image 7.png', 'image 8.png', 'image 9.png', 'image 11.png', 'image 12.png', 'image 13.png', 'image 14.png'].map((image, index) => (
                        <img key={index} src={`tecnologies/${image}`} alt={image.split(' ')[1].replace('.png', '')} loading="lazy" />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
