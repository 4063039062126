import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import HeroSection from './components/HeroSection/HeroSection';
import Services from './components/Services/Services';
import Projects from './components/Projects/Projects';
import Values from './components/Values/Values';
import Testimonials from './components/Testimonials/Testimonials';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div>
      <Header />
      <HeroSection />
      <Services />
      <Projects />
      <Values />
      <Testimonials />
      <FAQ />
      <Footer />
    </div>
  );
}

export default App;
