import React from 'react';
import emailjs from 'emailjs-com';
import './Footer.css';

const Footer = () => {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_7rzbt4o',      
            'template_ljcyh6s',     
            e.target,
            'YcM_H0JuRAb8T7wwz'     
        ).then((result) => {
            console.log(result.text);
            alert('Mensagem enviada com sucesso!');
        }, (error) => {
            console.log(error.text);
            alert('Erro ao enviar mensagem. Tente novamente mais tarde.');
        });
        
        e.target.reset();
    };

    return (
        <>
            <section id="contato" className="contact-section">
                <div className="contact-container">
                    <div className="contact-form">
                        <span className="contact-label">CONTATO</span>
                        <h2>Vamos trabalhar juntos!</h2>
                        <p>Gostaríamos muito de ouvir você! Envie-nos uma mensagem usando o formulário abaixo ou nos mande um e-mail. Estamos ansiosos para saber mais sobre o seu projeto!</p>

                        <form onSubmit={sendEmail}>
                            <div className="form-group">
                                <label htmlFor="name">Nome completo</label>
                                <input type="text" name="name" id="name" placeholder="Insira seu nome" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" placeholder="Insira seu email" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="company">Empresa</label>
                                <input type="text" name="company" id="company" placeholder="Opcional" />
                            </div>

                            <div className="form-group">
                                <label htmlFor="message">Como podemos ajudar?</label>
                                <textarea name="message" id="message" placeholder="Até 3000 caracteres" maxLength="3000" required></textarea>
                            </div>

                            <div className="form-group">
                                <label htmlFor="source">Como você nos encontrou?</label>
                                <input type="text" name="source" id="source" placeholder="Opcional" />
                            </div>

                            <button type="submit" className="submit-btn">Enviar</button>
                        </form>
                    </div>
                    
                    {/* Imagem com lazy loading e srcset para responsividade */}
                    <div className="contact-image">
                        <picture>
                            <source srcSet="background.png" media="(max-width: 800px)" />
                            <img src="background.png" alt="Code Screenshot" loading="lazy" />
                        </picture>
                    </div>
                </div>
            </section>
            
            {/* Rodapé */}
            <footer className="bottom-footer">
                <div className="bottom-left">
                    <h3>Galuma</h3>
                    <p>© 2024 Galuma. Todos os direitos reservados.</p>
                </div>
                <div className="bottom-right">
                    <a href="https://github.com/GalumaTech" target="_blank" rel="noopener noreferrer">
                        <img src="github.png" alt="GitHub Icon" className="footer-icon" />
                    </a>
                    <a href="https://www.instagram.com/GalumaTech" target="_blank" rel="noopener noreferrer">
                        <img src="instagram.png" alt="Instagram Icon" className="footer-icon" />
                    </a>
                </div>
            </footer>
        </>
    );
};

export default Footer;
