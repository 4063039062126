import React from 'react';
import './Projects.css';

const Projects = () => {
    return (
        <section id="projetos" className="projects-section">
            <span className="projetos-label">PORTFÓLIO</span>
            <h2>Projetos Recentes</h2>

            <div className="project-container">
                <div className="project-image">
                    <img
                        src="/casa-das-pedras.png"
                        alt="Casa das Pedras"
                        loading="lazy"
                    />
                </div>

                <div className="project-details">
                    <h3>Marmoraria Casa das Pedras</h3>
                    <p>No desenvolvimento do site institucional para a Marmoraria Casa das Pedras, uma das principais da Baixada Santista, focamos em criar uma plataforma moderna e funcional, que fortalece a presença digital da marca.</p>
                    <p>As etapas incluíram análise de necessidades, design intuitivo para uma navegação fluida, galeria visual para destacar projetos e materiais, integração com WhatsApp para contato direto, e otimização SEO local para aumentar a visibilidade.</p>
                    <p>O projeto reflete nosso compromisso com soluções personalizadas e eficazes, aprimorando a experiência do cliente e os resultados digitais.</p>                   
                </div>                
            </div>
            <a href="#contato" className="project-btn">Fale com um Especialista</a>
        </section>
    );
};

export default Projects;
