import React, { useState } from 'react';
import './FAQ.css';

const faqs = [
    {
        question: "Como a Galuma garante a qualidade dos projetos entregues?",
        answer: "Seguimos um rigoroso processo de desenvolvimento que inclui planejamento detalhado, testes contínuos e revisões de qualidade em cada etapa. Além disso, trabalhamos em estreita colaboração com você durante todo o projeto, garantindo que as suas expectativas sejam atendidas e que o resultado final esteja alinhado com as necessidades do seu negócio."
    },
    {
        question: "Qual é o suporte oferecido após a entrega?",
        answer: "Oferecemos suporte técnico completo após a entrega do projeto, incluindo manutenção, atualizações e ajustes conforme necessário. Nosso objetivo é garantir que o seu sistema continue funcionando de forma eficaz e sem interrupções."
    },
    {
        question: "Quais são as medidas de segurança adotadas?",
        answer: "Aplicamos práticas de segurança rigorosas em cada projeto, incluindo a utilização de criptografia, autenticação de usuários, e revisões periódicas para manter a segurança das informações."
    },
    {
        question: "Quanto tempo leva para desenvolver um projeto?",
        answer: "O tempo de desenvolvimento varia de acordo com a complexidade e os requisitos do projeto. Em média, pequenos projetos podem ser concluídos em algumas semanas, enquanto soluções mais robustas podem levar alguns meses. Sempre fazemos uma estimativa detalhada antes de começar, garantindo clareza no cronograma."
    },
    {
        question: "Vocês fazem projetos personalizados?",
        answer: "Sim! Na Galuma, cada projeto é desenvolvido sob medida, atendendo às necessidades específicas do seu negócio. Desde sites até aplicativos e sistemas completos, trabalhamos para garantir que a solução atenda perfeitamente ao que você precisa."
    },
    {
        question: "É possível parcelar?",
        answer: "Sim, oferecemos opções flexíveis de pagamento para se adequar ao seu orçamento, inclusive parcelamento, dependendo das condições do projeto. Entre em contato para discutirmos o melhor plano para você."
    }
];

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section id="faq" className="faq-section">
            <div className="faq-content">
                <span className="faq-label">FAQ</span>
                <h2>Perguntas Frequentes</h2>

                <div className="faqs">
                    {faqs.map((faq, index) => (
                        <div key={index} className="faq-item">
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                <h3>{faq.question}</h3>
                                <span>
                                    {activeIndex === index ? (
                                        <i className="fas fa-chevron-up"></i> // Seta para cima
                                    ) : (
                                        <i className="fas fa-chevron-down"></i> // Seta para baixo
                                    )}
                                </span>
                            </div>
                            {activeIndex === index && <div className="faq-answer"><p>{faq.answer}</p></div>}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQ;
